<template>
  <div class="page_container">
    <BackComponent :title="$t('depositFunds')" />
    <div>
      <img
        src="/img/chop-banner.webp"
        style="width: 100%; max-width: 100%; border-radius: 50px"
      />
    </div>
    <section class="account-details">
      <h5>{{ $t("selectPaymentProvider") }}</h5>
      <div
        v-for="(provider, index) in paymentProviders"
        :key="index"
        class="wallet-info"
      >
        <router-link :to="provider.link">
          <div class="account">
            <div>
              <img :src="provider.imgSrc" />
              <span>{{ provider.name }}</span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.42499 16.6004L12.8583 11.1671C13.5 10.5254 13.5 9.47539 12.8583 8.83372L7.42499 3.40039"
                stroke="#8C8787"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </router-link>
      </div>
    </section>
    <span id="open-shareinvite" class="hidden"></span>
  </div>
</template>

<script>
export default {
  name: "Setting",
  components: {
    BackComponent: () => import("@/components/ui/BackComponent"),
  },
  data() {
    return {
      paymentProviders: [
        {
          name: "Wave",
          imgSrc: "/img/payment/wave.png",
          link: "/wave-deposit",
        },
        {
          name: "Orange Money",
          imgSrc: "/img/payment/orange.png",
          link: "/orange-deposit",
        },
        {
          name: "MTN",
          imgSrc: "/img/payment/mtn.jpg",
          link: "/mtn-deposit",
        },
        {
          name: "Moov Money",
          imgSrc: "/img/payment/moov.png",
          link: "/moov-deposit",
        },
      ],
    };
  },
};
</script>

<style scoped>
.account-details {
  background: var(--background-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 160px);
  margin-top: 4px;
}

h5 {
  color: var(--text-color);
  font-family: "Satoshi";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.5px;
  margin-bottom: 12px;
}

.wallet-info {
  border-radius: 8px;
  background: var(--box-bg);
  color: var(--text-color);
  margin-bottom: 8px;
}

.wallet-info > a {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  color: var(--text-color);

  align-items: center;
  align-self: stretch;
}

.account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & div {
    display: flex;
    align-items: center;
  }
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  span {
    /* color: var(--Light-Mode-Base-Base-700, #383636); */
    color: var(--text-color);

    font-family: "Satoshi";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.1px;
  }
}
</style>
